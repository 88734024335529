
import { defineComponent, nextTick, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { showModal } from "@/core/helpers/dom";
import ResusableFunctions from "@/composables/ReusableFunctions";
import ClaimSettlementOfferDecline from "@/components/modals/ClaimSettlementOfferDecline.vue";

export default defineComponent({
  name: "settlement-offers-table-component",
  components: { ClaimSettlementOfferDecline },
  props: {
    redirect: String,
    policySummary: Object,
  },
  data() {
    return {
      offers: [{} as any],
      folioItem: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
      getSettlementOfferStatus,
    } = ResusableFunctions();
    const isSettlementModalVisible = ref(false);

    const details = ref({
      publicId: "",
      claimReference: "",
    });

    const displayDeclineModal = (publicId: string, claimReference: string) => {
      details.value = {
        publicId,
        claimReference,
      };

      setTimeout(() => {
        nextTick(() => {
          const modal = document.getElementById(
            "claim_settlement_offer_decline_modal"
          );
          if (modal) {
            showModal(modal);
          }
        });
      }, 100);

      isSettlementModalVisible.value = true;
    };

    const updateEDVStatus = (publicId: string, status: string) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(
          `${variables.SETTLEMENT_ROUTE}/${publicId}/discharge-voucher/${status}`
        )
          .then((response) => {
            variables.toastAlert(response.data.data.message, "success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    return {
      displayDatatable,
      useReusableNavigation,
      reinitialization,
      displayDeclineModal,
      getSettlementOfferStatus,
      updateEDVStatus,
      isSettlementModalVisible,
      details,
    };
  },
  created() {
    this.getSettlmentOffers();
  },
  methods: {
    // Get list of settlement offfers from all underwriters
    getSettlmentOffers() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/settlement")
          .then(({ data }) => {
            // Assign data to variables
            this.offers = data.data;

            if (this.offers) {
              this.displayDatatable(["basicExample"]);
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    approveSettlementOffer(id, publicId, type) {
      // const submitButton1 = document.getElementById(id);
      this.folioItem = publicId;

      // const mainType = type === "approve" ? "true" : "false";
      const message = "Settlement offer was appproved by a broker.";
      // const message =
      //   type === "approve" ? "" : "Settlement offer was appproved by a broker.";
      // if (ubmitButton1) {
      // Activate indicator
      // submitButton1.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        // submitButton1.removeAttribute("data-kt-indicator");

        // Send personal information update request
        if (JwtService.getToken()) {
          const formData = new FormData();

          formData.append("isAccepted", "true");
          formData.append("additionalComments", message);

          ApiService.setHeader();
          ApiService.post(
            `${variables.SETTLEMENT_ROUTE}/${publicId}/respond`,
            formData
          )
            .then(() => {
              // variables.toastAlert(response.data.data.message, "success");
              variables.toastAlert(
                "Settlement offer was accepted successfully.",
                "success"
              );

              this.getSettlmentOffers();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }, 2000);
      // }
    },
    previewSettlementOfferDocument(id, publicId, uniqueFileName) {
      // const submitButton1 = document.getElementById(id);

      // if (submitButton1) {
      // Activate indicator
      // submitButton1.setAttribute("data-kt-indicator", "on");

      // Delete cart item
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.setResponseType("blob");
        ApiService.get(
          `${variables.CLAIMS_ROUTE}/${publicId}/document/${uniqueFileName}`
        )
          .then((response) => {
            // submitButton1.removeAttribute("data-kt-indicator");
            const contentType = response.headers["content-type"];
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data], { type: contentType })
            );

            // Open the file in a new tab (handles both images and documents)
            window.open(fileURL, "_blank");
          })
          .catch(function(error) {
            // submitButton1.removeAttribute("data-kt-indicator");
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
      // }
    },
  },
});
